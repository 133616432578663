import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import PostsPager from '../components/PostsPager';
import { graphql } from 'gatsby';
import SeoPage from '../components/SEO/SeoPage';

const BlogsTemplate = ({ data, pageContext }) => {
  const { allPosts, deAuthor } = data;

  const AllPosts = allPosts.edges.map(post => {
    if (!post.node.data.author.document) {
      post.node.data.author = deAuthor;
      post.node.data.author.slug = 'dediabetes';
    } else {
      let author_id = post.node.data.author.slug;
      post.node.data.author = post.node.data.author.document;
      post.node.data.author.slug = author_id;
    }
    return post;
  });

  return (
    <Layout>
      <SeoPage
        title={`Archivo de Articulos`}
        description={`Archivo de Articulos de DeDiabetes`}
      />
      <Hero />
      <PostsPager
        posts={AllPosts}
        title="Archivo de Artículos"
        pageContext={pageContext}
      />
    </Layout>
  );
};

export const query = graphql`
  query AllPostsQuery($skip: Int!, $limit: Int!) {
    deAuthor: prismicAuthor(uid: { eq: "dediabetes" }) {
      data {
        name {
          text
        }
        description_short {
          text
        }
        headshot {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allPosts: allPrismicBlogPost(
      skip: $skip
      limit: $limit
      sort: { fields: data___release_date, order: DESC }
      filter: { data: { author: { uid: { ne: null }, lang: { eq: "es-es" } } } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            description {
              text
            }
            author {
              slug: uid
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      text
                    }
                    description_short {
                      text
                    }
                    headshot {
                      gatsbyImageData(layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
            type
            release_date(formatString: "MMM DD, YYYY", locale: "es-ES")
            categories {
              category {
                slug: uid
                document {
                  ... on PrismicCatego {
                    id
                    data {
                      name {
                        text
                      }
                      label {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogsTemplate;
